export const moveTop = (top = 0, behavior: ScrollBehavior = 'smooth') => {
  // Note: 데스크탑 사이즈인 경우 main이 100vh라 PageContent 스크롤을 핸들링 해야함
  const $content = matchMedia('screen and (min-width: 768px)').matches ? document.querySelector('.PageContent') : window

  $content.scroll({
    left: 0,
    top,
    behavior,
  })
}
