import React from 'react'
import Head from 'next/head'
import { isArray, truncate, trim, trimEnd } from 'lodash-es'
import { Maybe } from 'web-queries/src/types/generated'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  title: string
  description: Maybe<string>
  ogType?: string
  imageUrl?: string
  uri: string
  keywords?: string | string[]
  imageWidth?: number | string
  imageHeight?: number | string
}

export const DOMAIN = process.env.NEXT_PUBLIC_URL || 'http://localhost:3000'
const DEFAULT_IMAGE_URL = `${DOMAIN}/images/logo-black.png`
const MAX_META_DESCRIPTION_LENGTH = 80

const createFullUrl = (locale: string, defaultLocale: string, uri: string) => {
  const urls = [trimEnd(DOMAIN, '/')]

  if (locale !== defaultLocale) {
    urls.push(locale)
  }

  if (!['', '/'].includes(uri)) {
    urls.push(trim(uri, '/'))
  }

  return urls.join('/')
}
export default function Meta({
  title,
  description,
  ogType = 'music.musician',
  imageUrl = DEFAULT_IMAGE_URL,
  uri,
  keywords = ['홍대', '홍대 인디밴드', '인디밴드', '공연장'],
  imageWidth,
  imageHeight,
}: Props) {
  const { t } = useTranslation('common')
  const { locale, defaultLocale, locales } = useRouter()

  const wrappedDescription = description
    ? truncate(description, {
        length: MAX_META_DESCRIPTION_LENGTH,
      })
    : ''

  const url = createFullUrl(locale, defaultLocale, uri)

  return (
    <Head>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      {locales.map(l => (
        <link key={l} rel="alternate" href={createFullUrl(l, defaultLocale, uri)} hrefLang={l} />
      ))}
      <meta name="description" content={wrappedDescription} />
      <meta name="keywords" content={isArray(keywords) ? keywords.join(', ') : keywords} />
      <meta property="og:site_name" content={t('site-name')} />
      <meta property="og:type" content={ogType} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={imageUrl} />
      {imageWidth && <meta property="og:image:width" content={String(imageWidth)} />}
      {imageHeight && <meta property="og:image:height" content={String(imageHeight)} />}
      <meta property="og:url" content={url} />
      <meta property="og:description" content={wrappedDescription} />
      <meta name="twitter:description" content={wrappedDescription} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="winterwolf0412" />
      <meta name="twitter:title" content={title} />
    </Head>
  )
}
