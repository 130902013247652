import { ChevronUpIcon } from '@chakra-ui/icons'
import { Box, BoxProps, IconButton } from '@chakra-ui/react'
import { moveTop } from '../utils/scroll'

interface Props extends BoxProps {}

export default function MoveTopNavigator({ ...props }: Props) {
  return (
    <Box
      className="MoveTopNavigator"
      w="40px"
      h="40px"
      position="fixed"
      zIndex={3000}
      bottom={['80px', '100px', '24px']}
      right="24px"
      {...props}
    >
      <IconButton
        w="100%"
        h="100%"
        bgColor="gray.700"
        icon={<ChevronUpIcon />}
        aria-label="상단으로 이동"
        onClick={() => moveTop()}
      />
    </Box>
  )
}
