
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { AspectRatio, Box, Button, Flex } from '@chakra-ui/react'

import Meta from '../components/Meta'
import PageContent from '../components/PageContent'
import ErrorBoundary from '../components/ErrorBoundary'
import Link from 'next/link'
import Image from 'next/image'

export default function IndexPage() {
  const { t } = useTranslation('landingPage')

  return (
    <ErrorBoundary>
      <Meta title={t('common:site-name')} description={t('description')} uri="/" keywords={t('keywords')} />
      <PageContent
        display="flex"
        position="relative"
        flexDirection="column"
        alignItems={['center', null, 'flex-start']}
      >
        <Box display={['none', null, 'block']}>
          <Image src="/images/default_header_image.png" alt="indistreet logo" width={600} height={369} />
        </Box>
        <Flex w="100%" padding="8px" justifyContent="center" alignItems="center" direction="column" gap="24px">
          <Box w="100%" padding="24px" bgColor="whiteAlpha.300">
            <Box
              w="100%"
              dangerouslySetInnerHTML={{
                __html: t('welcome1'),
              }}
            />
          </Box>
          <Box w="100%" padding="24px" bgColor="whiteAlpha.300">
            {t('welcome2')}
          </Box>
          <Box w="100%" padding="24px" bgColor="whiteAlpha.300">
            {t('welcome3')}
          </Box>
          <Box
            w="100%"
            padding="24px"
            bgColor="whiteAlpha.300"
            dangerouslySetInnerHTML={{
              __html: t('common:notice-info'),
            }}
          />
          <Flex w="100%" gap="16px" flexDirection={['column', 'row']}>
            <Link href="/weekly" passHref>
              <Box as="a" flex="1">
                <Button w="100%" minHeight="48px" colorScheme="twitter" size="lg">
                  {t('weekly-live-link')}
                </Button>
              </Box>
            </Link>
            <Link href="/home" passHref>
              <Box as="a" flex="1">
                <Button w="100%" minHeight="48px" colorScheme="twitter" size="lg">
                  {t('home-link')}
                </Button>
              </Box>
            </Link>
          </Flex>
          <Box marginTop="48px" bgColor="gray.700" w="100%">
            <Box padding="24px">{t('donation-text')}</Box>
            <Link href="/donation" passHref>
              <Box as="a">
                <Button w="100%">{t('donation-link')}</Button>
              </Box>
            </Link>
          </Box>
        </Flex>
      </PageContent>
    </ErrorBoundary>
  )
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  