import { Box, BoxProps, useToken } from '@chakra-ui/react'
import React from 'react'
import BaseLayout from './BaseLayout'
import MoveTopNavigator from './MoveTopNavigator'

interface Props extends BoxProps {
  className?: string
  padding?: string | string[]
  children: React.ReactNode
}

export default function PageContent({ className, padding = ['8px', '16px', '24px'], children, ...props }: Props) {
  const [gray900] = useToken('colors', ['gray.900'])
  return (
    <BaseLayout>
      <MoveTopNavigator />
      <Box
        className={[className, 'PageContent'].join(' ')}
        h={['auto', null, '100vh']}
        overflowY={[null, null, 'scroll']}
        position="relative"
        padding={padding}
        {...props}
        css={{
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: gray900,
            borderRadius: '24px',
          },
        }}
      >
        {children}
      </Box>
    </BaseLayout>
  )
}
