import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import NavBar, { NAVBAR_MOBILE_HEIGHT, NAVBAR_WIDTH } from './NavBar'

interface Props {
  children: React.ReactNode
}

export default function BaseLayout({ children }: Props) {
  return (
    <Flex flexDir={['column', null, 'row']} paddingBottom={[`${NAVBAR_MOBILE_HEIGHT}px`, null, 0]}>
      <Box order={[2, null, 1]} h={['auto', null, '100vh']}>
        <NavBar />
      </Box>
      <Box
        order={[1, null, 2]}
        as="main"
        w={['100%', null, `calc(100% - ${NAVBAR_WIDTH}px)`]}
        h={['auto', null, '100vh']}
      >
        {children}
      </Box>
    </Flex>
  )
}
