import { Component } from 'react'
// import * as Sentry from '@sentry/browser'

interface Props {
  children: React.ReactNode | React.ReactNode[]
}

class ErrorBoundary extends Component<Props> {
  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo)
    /*Sentry.withScope(scope => {
      scope.setExtra('errorMessage', errorInfo)
      Sentry.captureException(error)
    })*/
  }

  render() {
    return this.props.children
  }
}

export default ErrorBoundary
